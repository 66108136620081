.diagnostic-works-student-table {
  .nush-table-wrapper {
    max-width: 430px;
  }
}

.nush-table-wrapper {
  box-shadow: 2px 2px 10px rgba(127, 137, 167, 0.15);
  border-radius: 20px;
  margin-bottom: 16px;
  padding-right: 6px;
  overflow: auto;
  background: var(--additional-page-bg);
  max-height: 600px;
  position: relative;
  z-index: 99;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: var(--additional-page-bg);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    outline: 1px solid transparent;
  }

  .add-row {
    cursor: pointer;
    background: var(--secondary-300);
    box-shadow: 0 2px 9px rgba(0, 29, 16, 0.15);
    border-radius: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--white);
    border: none;
    width: 100%;
    position: relative;
    width: max-content;
    min-height: 40px;
    padding: 12px 24px;
    padding-left: 46px;


    &:hover {
      background: var(--secondary-300);
      border: none;
    }

    &:before {
      position: absolute;
      left: 24px;
      top: 12px;
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z' fill='%23DFF4F6'/%3E%3Cpath d='M7.4452 10.6667V8.52798H5.33325V7.49874H7.4452V5.33333H8.55464V7.49874H10.6666V8.52798H8.55464V10.6667H7.4452Z' fill='%2328B4C3'/%3E%3C/svg%3E%0A");
    }
  }

  .add-column {
    cursor: pointer;
    background: var(--secondary-300);
    box-shadow: 0 2px 9px rgba(0, 29, 16, 0.15);
    border-radius: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--white);
    border: none;
    padding: 12px;
    width: 100%;
    position: relative;
    width: max-content;
    min-width: 40px;
    min-height: 40px;
    margin-left: 2px;

    &:not(.small) {
      padding: 12px 24px;
      padding-left: 46px;
      margin-left: 10px;

      &:before {
        left: 24px;
      }
    }

    &:hover {
      background: var(--secondary-300);
      border: none;
    }

    &:before {
      position: absolute;
      left: 12px;
      top: 12px;
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z' fill='%23DFF4F6'/%3E%3Cpath d='M7.4452 10.6667V8.52798H5.33325V7.49874H7.4452V5.33333H8.55464V7.49874H10.6666V8.52798H8.55464V10.6667H7.4452Z' fill='%2328B4C3'/%3E%3C/svg%3E%0A");
    }
  }
}

.nush-table {
  border-spacing: 0px;
  width: 100%;
  .hide-header {
    position: absolute;
    visibility: hidden;
  }
  tr {
    th,
    td {
      &:first-child {
        min-width: 238px;
        background: var(--white);
        padding-left: 20px;
        padding-right: 10px;
        border-right: 6px solid var(--additional-page-bg);
        position: sticky !important;
        top: 0;
        left: 0;
        z-index: 99;
        width: 50%;

        @media all and (max-width: 580px) {
          min-width: 150px;
          max-width: 150px;
          padding-left: 15px;
        }
      }
    }

    &:nth-child(n+2) td:first-child {
      height: 62px;
    }

    &:last-child td {

      .table-footer & {
        height: 72px;
      }

      &:first-child {
        padding-bottom: 3px;
      }

      &:nth-child(n+2) {
        height: 65px;

        >span {
          bottom: 4px;

          .table-footer & {
            bottom: 6px;
            top: 6px;
          }
        }
      }
    }

    nit-table-add-column {
      min-width: max-content !important;
      max-width: max-content !important;
      padding-top: 5px !important;
    }

    th,
    nit-table-add-column {
      display: table-cell;
      position: sticky;
      cursor: pointer;
      padding: 0;
      top: 0;
      z-index: 99;
      font-size: var(--font-size-body);
      line-height: 16px;

      &:nth-child(n+2) {
        padding-top: 4px;
        padding-bottom: 3px;
        min-width: 104px;
        max-width: 240px;
        color: var(--white);
        border-radius: 4px;

        .overflow {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: absolute;
          left: 12px;
          top: 14px;
          right: 12px;
          bottom: 14px;
        }

        span {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          height: 100%;
          background: var(--additional-grey-300);
          border-radius: 4px;
          padding: 14px 12px;
          margin-left: 1px;
          margin-right: 1px;
          min-height: 44px;

          &.practice-column {
            max-width: 100px;
            width: 100%;
            margin: 0 auto;
          }
        }
      }

      &:first-child {
        z-index: 100;
        position: relative;
        font-weight: bold;
        font-size: var(--font-size-h5);
        line-height: 18px;
        color: var(--primary-300);
        text-align: left;
        padding: 22px 80px 11px 20px;

        &:not(.footer-th):before {
          content: url('/assets/images/notepads.svg');
          position: absolute;
          right: 22px;
          top: 5px;
          width: 69px;
          height: 49px;

          @media all and (max-width: 580px) {
            display: none;
          }
        }
      }


      @media all and (max-width: 580px) {
        &.student {
          padding: 10px 0 10px 15px;
        }

        max-width: 70px;
        min-width: 70px;
        font-size: var(--font-size-h6);
        line-height: 14px;
        font-weight: 400;
      }
    }

    td {
      font-size: var(--font-size-body);
      color: var(--text-colors-title-300);
      line-height: 24px;
      font-weight: 500;

      &:nth-child(n+2) {
        font-weight: bold;
        padding: 0;
        text-align: center;
        font-size: var(--font-size-h5);
        line-height: var(--text-colors-title-300);
        color: var(--primary-300);
        position: relative;
        height: 62px;

        > span {
          height: auto;
          width: auto;
          position: absolute;
          top: 1px;
          right: 1px;
          bottom: 1px;
          left: 1px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 1px;
        }

        @media all and (max-width: 580px) {
          max-width: 70px;
          min-width: 70px;
          height: 42px;
        }
      }

      @media all and (max-width: 580px) {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
