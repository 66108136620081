<div kendoTooltip
     filter=".mark-inform"
     showOn="click"
     [tooltipTemplate]="template"
     [tooltipClass]="'mark-inform-tooltip'"
     [tooltipWidth]="205">
  <div class="mark-inform">Позначення</div>

  <ng-template #template>
    <div>
      <span class="option">зв. - Звільнено</span>
      <span class="option">н/а - Не атестовано</span>
      <span class="option">зарах. - Зараховано</span>
      <span class="option">не зарах. - Не зараховано</span>
      <span class="option">вивч. - Вивчено</span>
      <span class="option">н/о - Не оцінено</span>
      <span class="option">П - Початковий</span>
      <span class="option">C - Середній</span>
      <span class="option">Д - Достатній</span>
      <span class="option">В - Високий</span>
    </div>
    <div>
      <img class="green-tick" src="/assets/icons/green-tick.svg" alt="Green tick"> - Сформовано
    </div>
  </ng-template>
</div>
