import {Pipe, PipeTransform} from '@angular/core';
import {NitForm} from '@nit-forms';

@Pipe({
  name: 'selectedPopoverValue',
  /* eslint-disable */
    pure: false,
    standalone: true
})
export class SelectedPopoverValuePipe implements PipeTransform {

  transform(configControls: any, form: NitForm): string {
    const controls = Object.keys(configControls);
    const selectedControlName = controls.find(x => form.get(x)?.value !== null);
    if (!selectedControlName) return;

    return configControls[selectedControlName]?.controls?.find(x => form.get(selectedControlName)?.value === x.value)?.name;
  }

}
