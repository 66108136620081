import {Component} from '@angular/core';
import {TooltipDirective} from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'nit-mark-inform',
  templateUrl: './mark-inform.component.html',
  styleUrl: './mark-inform.component.scss',
  standalone: true,
  imports: [TooltipDirective]
})
export class MarkInformComponent { }
