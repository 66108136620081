import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {JournalService, UserService} from '@nit-services';
import {UntilDestroy} from '@ngneat/until-destroy';
import {NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {JournalClass} from '@nit-core/models/filter';
import {NushViewModes} from '@nit-core/global/domain/enums';
import {NitFieldWrapperComponent} from '@nit-forms';
import {DropdownComponent} from '../../../../../components/dropdown/dropdown.component';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'nit-class-dropdown',
  templateUrl: './class-dropdown.component.html',
  styleUrl: './class-dropdown.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ClassDropdownComponent),
    }
  ],
  standalone: true,
  imports: [NitFieldWrapperComponent, DropdownComponent, FormsModule]
})
export class ClassDropdownComponent implements OnChanges, OnInit {
  @Input() scheduleId?: string;
  @Input() myClassesOnly?: boolean;
  @Input() viewMode: NushViewModes;
  @Output() selectedClass: EventEmitter<JournalClass> = new EventEmitter<JournalClass>();

  classes: JournalClass[] = [];
  currentClass: JournalClass | null;
  disabled: boolean = false;
  touched: boolean = false;
  chosenClassId: string;
  nushViewModes: typeof NushViewModes = NushViewModes;
  classIds: string[];

  get className(): string {
    if (!this.chosenClassId) return '';
    if (this.classes?.length < 1) return '';
    const selectedClass = this.classes.find((testedClass: JournalClass) => testedClass.id === this.chosenClassId);
    if (!selectedClass) return '';

    return selectedClass.name;
  }

  constructor(private readonly _journalService: JournalService,
              private readonly _userService: UserService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scheduleId &&
      this.scheduleId &&
      this.viewMode !== this.nushViewModes.Child &&
      this.viewMode !== this.nushViewModes.Parent &&
      !changes.scheduleId.firstChange) {
      this._getClasses();
    }
  }

  ngOnInit(): void {
    if (this.viewMode !== this.nushViewModes.Child && this.viewMode !== this.nushViewModes.Parent) {
      this._getClasses();
    }
    this.classIds = this._userService.getClassTeacherClassIds();
  }

  canAccessAchievement(subject: string): boolean {
    const selectedClass = this.classes?.find(verifiableClass => verifiableClass.id === this.chosenClassId);

    return selectedClass.subjects.some(verifiableSubject =>
      verifiableSubject.subject === subject);
  }

  canShowAchievement(subject: string): boolean {
    const selectedClass = this.classes?.find(x => x.id === this.chosenClassId);
    const s = selectedClass.subjects.filter(x => x.subject === subject);

    return s?.length > 0;
  }

  onChange: (val: string) => void = () => { };
  onTouched = () => { };

  writeValue(val: string): void {
    this.chosenClassId = val;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  changeClass(classId: string): void {
    this.chosenClassId = classId;
    this.onChange(this.chosenClassId);
    this._findSelectedClass();
  }

  private _getClasses(): void {
    this.classes = [];
    this.currentClass = null;
    this._journalService.getNushClasses(this.scheduleId, this.myClassesOnly).subscribe(res => {
      const sorted = res.data.naturalSort('name');
      this.classes = this.viewMode === NushViewModes.ClassTeacher ?
        sorted.filter(journal => this.classIds.includes(journal.id)) : sorted;
      if (this.chosenClassId) {
        this.changeClass(this.chosenClassId);
      }
    });
  }

  private _findSelectedClass(): void {
    const selectedClass: JournalClass = this.classes.find(item => item.id === this.chosenClassId);
    this.selectedClass.emit(selectedClass);
  }
}
