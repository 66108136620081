<label class="small-block"
       [class.disabled]="disabled"
       [class.verbal-formed]="isVerbalControl">
  <div class="checkbox-horizontal">
    <input type="checkbox"
           [name]="controlName"
           [checked]="isChecked"
           [disabled]="disabled"
           (change)="change()">
    <span>
      <ng-content />
    </span>
  </div>
</label>
