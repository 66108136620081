import {Component, Input, forwardRef} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {NushDecisionSubject} from '@nit-core/models/nush-decision';
import {NitFieldWrapperComponent} from '../../../../../../../../core/forms/components/field-wrapper/field-wrapper.component';
import {DropdownComponent} from '../../../../../components/dropdown/dropdown.component';

@UntilDestroy()
@Component({
  selector: 'nit-subject-dropdown',
  templateUrl: './subject-dropdown.component.html',
  styleUrl: './subject-dropdown.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SubjectDropdownComponent)
    }
  ],
  standalone: true,
  imports: [NitFieldWrapperComponent, DropdownComponent, FormsModule]
})
export class SubjectDropdownComponent implements ControlValueAccessor {
  @Input() subjects: NushDecisionSubject[];

  disabled: boolean = false;
  touched: boolean = false;
  chosenSubject: NushDecisionSubject;

  private _value: any;

  onChange = (val: any) => { this._value = val; };
  onTouched = () => { };

  writeValue(val: NushDecisionSubject): void {
    this.chosenSubject = val;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  changeSubject(subject: NushDecisionSubject): void {
    this.chosenSubject = subject;
    this.onChange(this.chosenSubject);
  }
}
