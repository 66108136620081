import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'nit-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone: true
})

export class TableComponent {
  @Input() isFooter: boolean = false;
}
