<label id="achievement-mark"
       class="td-rate"
       [for]="id"
       [class.empty]="!inputControl?.value && !inputControl?.focused &&
         !(configControls | selectedPopoverValue: form) && !readOnly"
       [class.read-only]="readOnly"
       [class.input-focused]="inputControl?.focused"
       [formGroup]="form"
       (click)="showPopover()">
  <div class="value"
       [class.type-value-0]="(configControls | selectedPopoverValue: form) === 'Сформовано' &&
         form.get('isVerbalFormed')?.value && (!isGeneralCharacteristics || is1To4Grade ||
         rate.type === generalAchievementValue)"
       [class.type-value-2]="(configControls | selectedPopoverValue: form) && this.grading.isLeveledGradingActive"
       [class.type-value-3]="(configControls | selectedPopoverValue: form) && this.grading.isDigitalGradingActive"
       [class.show]="(configControls | selectedPopoverValue: form)"
       [class.absent]="form.get('absense')?.value !== null">
    {{form.get('isVerbalFormed').value ? '' : (configControls | selectedPopoverValue: form)}}
  </div>
  <nit-mark-input #inputControl
                  formControlName="rating"
                  [id]="id"
                  [class.hide]="isMarkInputHidden" />
</label>

<div *ngIf="form.value.description"
     class="comment"
     kendoTooltip
     tooltipClass="nit-tooltip"
     [title]="form.get('description').value">
</div>
