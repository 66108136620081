import {Component, Input, Self} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ControlValueAccessor, NgControl, FormsModule} from '@angular/forms';
import {TextBoxDirective} from '@progress/kendo-angular-inputs';

@UntilDestroy()
@Component({
  selector: 'nit-mark-input',
  templateUrl: './mark-input.component.html',
  styleUrl: './mark-input.component.scss',
  standalone: true,
  imports: [TextBoxDirective, FormsModule],
})
export class MarkInputComponent implements ControlValueAccessor {
  @Input() id: string;
  @Input() formControlName: string = '';
  @Input() disabled: boolean = false;
  @Input() value: number;

  startValue: number;
  touched: boolean = false;
  focused: boolean = false;

  private _value: any;

  constructor(@Self() private readonly ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  onChange = (val: any) => { this._value = val; };
  onTouched = () => { };

  focus(): void {
    this.focused = true;
  }

  focusout(): void {
    this.focused = false;
    if (this.startValue !== this.value) {
      this.startValue = this.value;
      this.change();
    }
  }

  change(): void {
    this.onChange(this.value);
  }

  writeValue(val: number): void {
    this.value = val;
    this.startValue = val;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
